import React, { useState } from 'react';
import Tour from 'reactour';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const isMobile = () => window.innerWidth <= 768;

const steps = [
  {
    selector: '.db',
    content: 'This is your dashboard where you can see an overview of your activities.',
  },
  {
    selector: isMobile() ? '.search-partners-mobile' : '.search-partners',
    content: 'Use this section to search for partners.',
  },
  {
    selector: '.open-profile',
    content: 'Click here to open your profile.',
  },
  {
    selector: '.my-ratings',
    content: 'View your ratings here.',
  },
  {
    selector: '.disputes',
    content: 'Manage your disputes in this section.',
  },

];

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[6],
  zIndex: 1000,
  textAlign: 'center',
  minWidth: 300,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const AppTour = ({ onClose }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);

  const handleSetFirstTimeUser = () => {
    setShowWelcome(false);
    if (onClose) onClose();
  };
  const handleSkip = () => {
    setShowWelcome(false);
  };

  return (
    <>
      {showWelcome && (
        <StyledPaper elevation={10}>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Clearbiz!
          </Typography>
          <Box sx={{ mt: 2 }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => setIsTourOpen(true)}
            >
              Start Tour
            </StyledButton>
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={handleSkip}
            >
              Skip
            </StyledButton>
          </Box>
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => {
              setIsTourOpen(false);
              handleSetFirstTimeUser();
            }} />
        </StyledPaper>
      )}
    </>
  );
};

export default AppTour;