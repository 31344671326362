import React, { useEffect, useState } from "react";
import Select from "react-select";
import Sidebar from "../../components/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getLableAndValuesFromData,
  dispatchAll,
  getDistricts,
  getStates,
} from "../../utils";
import { toast } from "react-toastify";
import { Box, Grid, Typography, Switch, FormControlLabel,Tooltip } from "@mui/material";
import Loader from "../../components/loader";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getProfileAction,
  clearGetProfileActionData,
  globalConfigAction,
  putProfileAction,
  clearPutProfileActionData,
  clearSendOtpData,
  clearVerifyOtpData,
  sendOtpAction,
  verifyOtpAction,
} from "../../store/actions";
import * as Yup from "yup";
import { designationOptions } from "../../constants/designationOptions";

const newcustomer = Yup.object({
  name: Yup.string().required("Please enter name*"),
  city: Yup.string().required("Please enter city*"),
  email: Yup.string().email().required("Please enter your email * "),
  businessname: Yup.string().required("Please enter your business name*"),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
    .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
    .matches(/^\d*$/, "Phone number should not contain alphabetic characters")
    .required("Please enter a valid 10-digit phone number"),

  pan: Yup.string().length(10).required("Please enter your PAN*"),

  industry: Yup.object().shape({
    value: Yup.number().required("Please select Industry"),
    label: Yup.string().required(),
  }),
  company: Yup.object({
    value: Yup.number().required("Please select Company"),
    label: Yup.string().required(),
  }).required("Please select Company"),
  partner: Yup.object({
    value: Yup.number().required("Please select Partner"),
    label: Yup.string().required(),
  }).required("Please select Partner"),
  district: Yup.object({
    value: Yup.string().required("Please select District"),
    label: Yup.string().required(),
  }).required("Please select District"),
  state: Yup.object({
    value: Yup.string().required("Please select State"),
    label: Yup.string().required(),
  }).required("Please select State"),

  date: Yup.date().required("Date of Incorporation is required"),

  AuthorizedMobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
    .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
    .matches(/^\d*$/, "Phone number should not contain alphabetic characters")
    .nullable(true),
  AuthorizedName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Name should contain only letters")
    .nullable(true),

  alternatePhoneNumber: Yup.string()
    .nullable(true)
    .transform((value) => (value === "" ? null : value))
    .when("$isRequired", {
      is: true,
      then: Yup.string()
        .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
        .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number") // Ensures 10 digits starting with 6-9
        .matches(
          /^\d*$/,
          "Phone number should not contain alphabetic characters"
        ), // Ensures only numeric values
    }),
});

const Profile = () => {
  const dispatch = useDispatch();
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [industryTypes, setIndustryTypes] = React.useState([]);
  const [partnerTypes, setPartnerTypes] = React.useState([]);
  const [phoneOtpSent, setPhoneOtpSent] = useState(false);
  const [phoneOtpTimer, setPhoneOtpTimer] = useState(300);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [emailOtpTimer, setEmailOtpTimer] = useState(300);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [verifyIdentifier, setVerifyIdentifier] = useState("");
  const [isEditEmailMode, setIsEditEmailMode] = useState(false);
  const [isEditNumberMode, setIsEditNumberMode] = useState(false);

  const { successData: globalConfigData } =
    useSelector((state) => state.globalConfigData) || {};
  const { loading, successData: profileData } =
    useSelector((state) => state.profileData) || {};
  const {
    loading: updateLoading,
    successData: updateProfileData,
    error: updateError,
    errorInfo: updateErrorInfo,
  } = useSelector((state) => state.updateProfileData) || {};

  const {
    successData: sendOtpSuccessData,
    loading: sendOtpLoading,
    error: sendOtpError,
    errorInfo: sendOtpErrorInfo,
  } = useSelector((state) => state.sendOtpReducer) || {};
  const {
    successData: verifyOtpSuccessData,
    loading: verifyOtpLoading,
    error: verifyOtpError,
    errorInfo: verifyOtpErrorInfo,
  } = useSelector((state) => state.verifyOtpReducer) || {};

  const handleSendOtp = (identifier) => {
    setIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(sendOtpAction({ identifier: values.email }));
        break;
      case "phoneNumber":
        dispatch(sendOtpAction({ identifier: values.phoneNumber }));
        break;
      default:
        toast.error("Please try again");
    }
  };

  const handleVerifyOtp = (identifier) => {
    setVerifyIdentifier(identifier);
    switch (identifier) {
      case "email":
        dispatch(
          verifyOtpAction({
            identifier: values.email,
            otp: values.emailOtp,
          })
        );
        break;
      case "phoneNumber":
        dispatch(
          verifyOtpAction({
            identifier: values.phoneNumber,
            otp: values.phoneOtp,
          })
        );
        break;
      default:
        toast.error("Please try again");
    }
  };

  const statesData = getStates();
  const styles = {
    heading1: {
      color: "#2D4356",
      fontFamily: "sans-serif",
    },
    editableField: {
      backgroundColor: "#ffffff",
      border: "1.5px solid #2b4257",
      borderRadius: "15px",
      padding: "10px",
      width: "100%",
      minWidth:'290px'
    },
    readOnlyField: {
      backgroundColor: "#f5f5f5",
      border: "1px solid #ced4da",
      borderRadius: "15px",
      padding: "10px",
      width: "100%",
      minWidth:'290px'
        },
    selectStyles: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: "15px",
        padding: "2px",
        width: "90%",
        minWidth:'290px',
        backgroundColor: state.isDisabled ? "#f5f5f5" : "#ffffff",
        border: state.isDisabled ? "1px solid #ced4da" : "1.5px solid #2b4257",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 9999,
      }),
    },
  };

  React.useEffect(() => {
    dispatch(getProfileAction());
    dispatch(globalConfigAction());
  }, [dispatch]);
  React.useEffect(() => {
    if (globalConfigData) {
      setCompanyTypes(getLableAndValuesFromData(globalConfigData.company_type));
      setIndustryTypes(
        getLableAndValuesFromData(globalConfigData.industry_type)
      );
      setPartnerTypes(getLableAndValuesFromData(globalConfigData.partner_type));
    }
  }, [globalConfigData]);

  React.useEffect(() => {
    if (updateProfileData) {
      toast.success("Profile updated successfully");
      setIsEditMode(false);
      dispatch(getProfileAction());
    }
  }, [updateProfileData, dispatch]);
  React.useEffect(() => {
    return () =>
      dispatchAll(
        [clearGetProfileActionData, clearPutProfileActionData],
        dispatch
      );
  }, [dispatch]);

  const profileDetails = React.useMemo(() => {
    if (profileData) {
      return profileData;
    }
  }, [profileData]);

  const initialValues = {
    state:
      statesData?.find((v) => v.value === profileDetails?.business?.state) ??
      {},
    district: profileDetails?.business?.district
      ? getDistricts(profileDetails.business.state)?.find(
        (v) => v.value === profileDetails.business.district
      ) ?? {}
      : {},
    pan: profileDetails?.business?.masked_pan ?? "",
    confirmPanNumber: profileDetails?.business?.masked_pan ?? "",

    // confirmPanNumber
    date: profileDetails?.business?.incorporation_date ?? "",
    businessname: profileDetails?.business?.name ?? "",
    company:
      companyTypes.find((v) => v.value === profileDetails?.business?.company) ??
      {},
    industry:
      industryTypes.find(
        (v) => v.value === profileDetails?.business?.industry
      ) ?? {},
    partner:
      partnerTypes.find((v) => v.value === profileDetails?.user_type) ?? {},
    phoneNumber: profileDetails?.phone_number ?? "",
    email: profileDetails?.email ?? "",
    city: profileDetails?.business?.city ?? "",
    name: profileDetails?.first_name ?? "",
    AuthorizedName: profileDetails?.business?.authorized_user_name ?? "",
    Designation:
      designationOptions.find(
        (v) => v.label === profileDetails?.business?.authorized_user_designation
      ) || {},
    AuthorizedMobileNumber:
      profileDetails?.business?.authorized_user_phone_number ?? "",
    alternatePhoneNumber: profileDetails?.alternate_phone_number ?? "",
    notify_partner_on_rating: profileDetails?.notify_partner_on_rating || false,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: newcustomer,
    onSubmit: (values) => {
      const userData = {
        user: {
          email: values.email,
          user_type: values.partner.value,
          first_name: values.name,
          phone_number: values.phoneNumber,
          alternate_phone_number: values.alternatePhoneNumber || "null",
          notify_partner_on_rating: values.notify_partner_on_rating,
        },
        business: {
          industry: values.industry.value,
          company: values.company.value,
          name: values.businessname,
          city: values.city,
          incorporation_date: values.date,
          state: values.state.value,
          district: values.district.value,
          authorized_user_name: values.AuthorizedName,
          authorized_user_designation: values.Designation.label,
          authorized_user_phone_number: values.AuthorizedMobileNumber,
        },
      };
      dispatch(
        putProfileAction({
          endPoint: `profile/${profileDetails.id}/`,
          ...userData,
        })
      );
    },
  });

  const onChangeDropDown = (selectedKey, selectedValue) => {
    if (selectedKey === "state") {
      setFieldValue("district", {});
    }
    setFieldValue(selectedKey, selectedValue);
  };

  const districtData = React.useMemo(() => {
    if (Object.keys(values.state).length > 0) {
      return getDistricts(values.state.value);
    }
  }, [values.state]);

  const [isEditMode, setIsEditMode] = useState(false);

  React.useEffect(() => {
    if (updateError) {
      toast.error(updateErrorInfo);
      dispatch(updateProfileData());
    }
  }, [updateError, updateErrorInfo, dispatch, updateProfileData]);

  useEffect(() => {
    if (sendOtpSuccessData && identifier) {
      if (identifier === "email") {
        toast.success("OTP sent to email");
        setEmailOtpSent(true);
        setEmailOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      } else if (identifier === "phoneNumber") {
        toast.success("OTP sent to phone number");
        setPhoneOtpSent(true);
        setPhoneOtpTimer(300);
        setIdentifier(""); // reset identifier
        dispatch(clearSendOtpData());
      }
    } else if (sendOtpError) {
      toast.error(sendOtpErrorInfo || "Failed to send OTP");
      dispatch(clearSendOtpData());
    }
  }, [
    sendOtpSuccessData,
    sendOtpError,
    sendOtpErrorInfo,
    identifier,
    dispatch,
  ]);

  useEffect(() => {
    if (phoneOtpSent && phoneOtpTimer > 0) {
      const timer = setInterval(() => {
        setPhoneOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (phoneOtpTimer === 0) {
      setPhoneOtpSent(false);
    }
  }, [phoneOtpSent, phoneOtpTimer]);

  useEffect(() => {
    if (emailOtpSent && emailOtpTimer && emailOtpTimer > 0) {
      const timer = setInterval(() => {
        setEmailOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (emailOtpTimer === 0) {
      setEmailOtpSent(false);
    }
  }, [emailOtpSent, emailOtpTimer]);

  useEffect(() => {
    if (verifyOtpSuccessData && verifyIdentifier) {
      if (verifyIdentifier === "email") {
        toast.success("Email verified successfully");
        setEmailOtpSent(false);
        setIsEmailVerified(true);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      } else if (verifyIdentifier === "phoneNumber") {
        toast.success("Phone number verified successfully");
        setIsPhoneVerified(true);
        setPhoneOtpSent(false);
        setIdentifier(""); // reset identifier
        dispatch(clearVerifyOtpData());
      }
    } else if (verifyOtpError) {
      toast.error(verifyOtpErrorInfo || "Failed to verify OTP");
      dispatch(clearVerifyOtpData());
    }
  }, [
    verifyOtpSuccessData,
    verifyOtpError,
    verifyOtpErrorInfo,
    verifyIdentifier,
    dispatch,
  ]);

  return (
    <Box sx={{ display: "flex", minHeight: "90vh" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={6}>
        <Grid container spacing={1}>
          <Grid
            container
            direction="row"
            style={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography
                variant="h4"
                style={{fontSize: "36px",fontWeight: "bold", color: "#2b4257"}}>
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} style={{ textAlign: "right" }}>
              <Typography
                variant="h6"
                style={{fontSize: "18px",fontWeight: "bold",color: "#2b4257"}}>
                <b>Active Plan : </b>
                {profileData?.premium_type === 1 && " CB Pro"}
                {profileData?.premium_type === 2 && " CB plus"}
                {profileData?.premium_type === 3 && " CB Elite"}
                {profileData?.premium_type === 4 && " CB E+E"}
              </Typography>
              <Typography variant="body1" style={{fontSize: "18px",fontWeight: "bold",color: "#2b4257",}}>
                <b>Expiry : </b>
                {profileData?.plan_expiry_date}
              </Typography>
              <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ textAlign: "center" }}
                  
                >
                    <Tooltip title="Enable this option to notify  your partner through sms when a rating is updated.">
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={values.notify_partner_on_rating}
                            onChange={handleChange}
                            name="notify_partner_on_rating"
                            color="primary"
                            disabled={!isEditMode}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: '12px',color:'#2b4257' }}>
                            Notify Partner on Rating Update
                          </Typography>
                        }
                        sx={{ color: "#2b4257", justifyContent: 'flex-end',ml:'30%' }}
                      />
                    </Tooltip>
                </Grid>
            </Grid>
          </Grid>

          {loading || updateLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <Grid container item xs={12} sm={12} md={12} lg={12} mt={2}  style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
              <form className="row" style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid item xs={12} sm={12} md={12} lg={12} mb={1} mt={5} style={{ textAlign: "center" }}>
                  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#0e3c58" }}>Business Details</Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} mt={2} style={{ justifyContent: "center"}}>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="businessname"
                      value={values.businessname}
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      id="businessname"
                      placeholder="Business Name"
                      readOnly={!isEditMode}
                    />

                    {errors.businessname && touched.businessname ? (
                      <p className="form-error error-message">
                        {errors.businessname}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="industry"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Industry
                    </label>
                    <Select
                      defaultValue={values.industry}
                      value={values.industry}
                      name="industry"
                      id="industry"
                      onChange={(selectedOption) =>
                        setFieldValue("industry", selectedOption)
                      }
                      options={industryTypes}
                      isDisabled={!isEditMode}
                      styles={styles.selectStyles}
                    />
                    {errors.industry && touched.industry ? (
                      <p className="form-error error-message">
                        {errors.industry.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="company"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Company
                    </label>
                    <Select
                      defaultValue={values.company}
                      value={values.company}
                      name="company"
                      id="company"
                      onChange={(selectedOption) =>
                        setFieldValue("company", selectedOption)
                      }
                      options={companyTypes}
                      isDisabled={!isEditMode}
                      styles={styles.selectStyles}
                    />
                    {errors.company && touched.company ? (
                      <p className="form-error error-message">
                        {errors.company.value}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="user_type"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Business Type
                    </label>
                    <Select
                      defaultValue={values.partner}
                      value={values.partner}
                      name="partner"
                      id="partner"
                      onChange={(selectedOption) =>
                        setFieldValue("partner", selectedOption)
                      }
                      options={partnerTypes}
                      isDisabled={!isEditMode}
                      styles={styles.selectStyles}
                    />
                    {errors.partner && touched.partner ? (
                      <p className="form-error error-message">
                        {errors.partner.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="panNumber"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Pan Number
                    </label>
                    <input
                      type="text"
                      name="pan"
                      id="pan"
                      value={values.pan}
                      style={{
                        borderRadius: "15px",
                        padding: "10px",
                        width: "100%",
                        minWidth:'290px'
                      }}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      onBlur={handleBlur}
                      className="form-control"
                      placeholder="Pan"
                      readOnly={true}
                    />

                    {errors.pan && touched.pan ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.pan}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="panNumber"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Date Of Incorporation
                    </label>
                    <input
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      id="date"
                      placeholder=""
                      readOnly={true}
                      style={{
                        borderRadius: "15px",
                        padding: "10px",
                        width: "100%",
                        minWidth:'290px'
                      }}
                    />
                    {errors.date && touched.date ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.date}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mb={1} mt={5} style={{ textAlign: "center" }}>
                  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#0e3c58" }}>Owner Details</Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} mt={2} style={{ justifyContent: "center"}}>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="name"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      placeholder="Name"
                      readOnly={!isEditMode}
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                    />
                    {errors.name && touched.name ? (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.name}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Email Id
                      {isEditMode && !isEditEmailMode && (
                        <button
                          style={{
                            color: "#2b4257",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            textDecoration: "underline",
                          }}
                          onClick={() => setIsEditEmailMode(true)}
                        >
                          Change Email Id
                        </button>
                      )}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      autoComplete="off"
                      placeholder="Email Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      readOnly={!isEditEmailMode}
                      disabled={
                        (emailOtpSent || isEmailVerified) && !isEditEmailMode
                      }
                    />
                    {errors.email && touched.email && (
                      <p className="form-error" style={{ color: "red" }}>
                        {errors.email}
                      </p>
                    )}
                    {!errors.email && values.email && isEditEmailMode && (
                      <>
                        {emailOtpSent && (
                          <>
                            <span>OTP Expires in :</span>{" "}
                            <span className="text-danger">
                              {Math.floor(emailOtpTimer / 60)}:
                              {emailOtpTimer % 60 < 10 ? "0" : ""}
                              {emailOtpTimer % 60}
                            </span>
                            {"   "}
                          </>
                        )}
                        <div className="signup-otp-container">
                          {isEditEmailMode && (
                            <>
                              {!emailOtpSent && !isEmailVerified && (
                                <>
                                  <button
                                    type="button"
                                    className="signup-otp-button"
                                    disabled={sendOtpLoading}
                                    onClick={() => handleSendOtp("email")}
                                  >
                                    {sendOtpLoading ? (
                                      <CircularProgress
                                        size={24}
                                        style={{ color: "#fff" }}
                                      />
                                    ) : (
                                      "Send OTP"
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="signup-otp-button"
                                    onClick={() => {
                                      // Reset state and remove OTP related elements
                                      setEmailOtpSent(false);
                                      setIsEditEmailMode(false); // Reset isEditEmailMode
                                      setFieldValue(
                                        "email",
                                        profileDetails.email
                                      ); // Reset emailOtp value
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                              {emailOtpSent && (
                                <>
                                  <input
                                    type="text"
                                    name="emailOtp"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="signup-otp-input"
                                    placeholder="OTP sent to email"
                                  />
                                  <button
                                    type="button"
                                    className="signup-otp-button"
                                    disabled={
                                      verifyOtpLoading ||
                                      values.emailOtp?.length !== 6
                                    }
                                    onClick={() => handleVerifyOtp("email")}
                                  >
                                    Submit OTP
                                  </button>
                                  <button
                                    type="button"
                                    className="signup-otp-button"
                                    onClick={() => {
                                      // Reset state and remove OTP related elements
                                      setEmailOtpSent(false);
                                      setIsEditEmailMode(false); // Reset isEditEmailMode
                                      setFieldValue(
                                        "email",
                                        profileDetails.email
                                      ); // Reset emailOtp value
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          {emailOtpSent && (
                            <>
                              {errors.emailOtp && touched.emailOtp && (
                                <p className="form-error error-message remove-margin">
                                  {errors.emailOtp}
                                </p>
                              )}
                              <span className="signup-otp-timer">
                                <button
                                  className="signup-otp-action"
                                  onClick={() => {
                                    handleSendOtp("email");
                                    setEmailOtpSent(false);
                                  }}
                                >
                                  Resend OTP
                                </button>{" "}
                                or
                                <button
                                  className="signup-otp-action"
                                  onClick={() => {
                                    setEmailOtpSent(false);
                                    setIsEditEmailMode(false); // Reset isEditEmailMode
                                  }}
                                >
                                  {" "}
                                  Change email
                                </button>
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="phoneNumber"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Mobile Number
                      {isEditMode && !isEditNumberMode && (
                        <button
                          style={{
                            color: "#2b4257",
                            cursor: "pointer",
                            border: "none",
                            borderRadius: "4px",
                            marginLeft: "5px",
                            textDecoration: "underline",
                          }}
                          onClick={() => setIsEditNumberMode(true)}
                        >
                          Change Number
                        </button>
                      )}
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Mobile Number"
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      readOnly={!isEditNumberMode}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      id="phoneNumber"
                      onInput={(e) => {
                        // Use a regular expression to remove non-numeric characters
                        e.target.value = e.target.value.replace(/\D/g, "");
                        handleChange(e);
                      }}
                      disabled={
                        (phoneOtpSent || isPhoneVerified) && isEditNumberMode
                      }
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <p className="form-error error-message">
                        {errors.phoneNumber}
                      </p>
                    ) : null}
                    {!errors.phoneNumber &&
                      values.phoneNumber &&
                      isEditNumberMode && (
                        <>
                          {phoneOtpSent && (
                            <>
                              <span>OTP Expires in :</span>{" "}
                              <span className="text-danger">
                                {Math.floor(phoneOtpTimer / 60)}:
                                {phoneOtpTimer % 60 < 10 ? "0" : ""}
                                {phoneOtpTimer % 60}
                              </span>
                              {"   "}
                            </>
                          )}
                          <div className="signup-otp-container">
                            {isEditNumberMode && (
                              <>
                                {!phoneOtpSent && !isPhoneVerified && (
                                  <>
                                    <button
                                      type="button"
                                      className="signup-otp-button"
                                      onClick={() =>
                                        handleSendOtp("phoneNumber")
                                      }
                                    >
                                      {sendOtpLoading ? (
                                        <CircularProgress
                                          size={24}
                                          style={{ color: "#fff" }}
                                        />
                                      ) : (
                                        "Send OTP"
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      className="signup-otp-button"
                                      onClick={() => {
                                        // Reset state and remove OTP related elements
                                        setPhoneOtpSent(false);
                                        setIsEditNumberMode(false); // Reset isEditNumberMode
                                        setFieldValue(
                                          "phoneNumber",
                                          profileDetails.phone_number
                                        ); // Reset phoneOtp value
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                                {phoneOtpSent && (
                                  <>
                                    <input
                                      type="text"
                                      name="phoneOtp"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="signup-otp-input"
                                      placeholder="OTP sent to phone"
                                    />
                                    <button
                                      type="button"
                                      className="signup-otp-button"
                                      onClick={() =>
                                        handleVerifyOtp("phoneNumber")
                                      }
                                      disabled={
                                        verifyOtpLoading ||
                                        values.phoneOtp?.length !== 6
                                      }
                                    >
                                      Submit OTP
                                    </button>
                                    <button
                                      type="button"
                                      className="signup-otp-button"
                                      onClick={() => {
                                        // Reset state and remove OTP related elements
                                        setPhoneOtpSent(false);
                                        setIsEditNumberMode(false); // Reset isEditNumberMode
                                        setFieldValue(
                                          "phoneNumber",
                                          profileDetails.phone_number
                                        ); // Reset phoneOtp value
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                            {phoneOtpSent && (
                              <>
                                {errors.phoneOtp && touched.phoneOtp && (
                                  <p className="form-error error-message remove-margin">
                                    {errors.phoneOtp}
                                  </p>
                                )}
                                <span className="signup-otp-timer">
                                  <button
                                    className="signup-otp-action"
                                    onClick={() => {
                                      handleSendOtp("phoneNumber");
                                      setPhoneOtpSent(false);
                                    }}
                                  >
                                    Resend OTP
                                  </button>{" "}
                                  or
                                  <button
                                    className="signup-otp-action "
                                    onClick={() => setPhoneOtpSent(false)}
                                  >
                                    {" "}
                                    Change mobile number
                                  </button>
                                </span>
                              </>
                            )}
                          </div>
                        </>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="alternatePhoneNumber"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Alternate Mobile Number
                    </label>
                    <input
                      type="text"
                      name="alternatePhoneNumber"
                      placeholder="Alternate Mobile Number"
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={10}
                      value={values.alternatePhoneNumber}
                      id="alternatePhoneNumber"
                      readOnly={!isEditMode}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        handleChange(e);
                      }}
                    />
                    {errors.alternatePhoneNumber &&
                      touched.alternatePhoneNumber ? (
                      <p className="form-error error-message">
                        {errors.alternatePhoneNumber}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="user_type"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      State
                    </label>
                    <Select
                      defaultValue={values.state}
                      value={values.state}
                      name="state"
                      id="state"
                      onChange={(selectedOption) =>
                        onChangeDropDown("state", selectedOption)
                      }
                      options={statesData}
                      isDisabled={true}
                      styles={styles.selectStyles}
                    />
                    {errors.state && touched.state ? (
                      <p className="form-error error-message">
                        {errors.state.value}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="user_type"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      District
                    </label>
                    <Select
                      defaultValue={values.district}
                      value={values.district}
                      name="district"
                      id="district"
                      onChange={(selectedOption) =>
                        onChangeDropDown("district", selectedOption)
                      }
                      options={districtData}
                      isDisabled={!isEditMode}
                      styles={styles.selectStyles}
                    />

                    {errors.district && touched.district ? (
                      <p className="form-error error-message">
                        {errors.district.value}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} mb={2} ml={3}>
                    <label
                      htmlFor="city"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={values.city}
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      id="city"
                      placeholder="City"
                      readOnly={!isEditMode}
                    />
                    {errors.city && touched.city ? (
                      <p className="form-error error-message">{errors.city}</p>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mb={1} mt={5} style={{ textAlign: "center" }}>
                  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#0e3c58" }}>Authorized Person Details</Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} mt={2} mb={2} style={{ justifyContent: "center", gap: "15px" }}>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} ml={3}>
                    <label
                      htmlFor="AuthorizedName"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      User Name
                    </label>
                    <input
                      type="text"
                      id="AuthorizedName"
                      name="AuthorizedName"
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      value={values.AuthorizedName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      placeholder="Name"
                      readOnly={!isEditMode}
                    />
                    {errors.AuthorizedName && touched.AuthorizedName ? (
                      <p className="form-error error-message">
                        {errors.AuthorizedName}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} ml={3}>
                    <label
                      htmlFor="Designation"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Designation
                    </label>
                    <Select
                      defaultValue={values.Designation}
                      value={values.Designation}
                      name="Designation"
                      id="Designation"
                      onChange={(selectedOption) =>
                        onChangeDropDown("Designation", selectedOption)
                      }
                      options={designationOptions}
                      isDisabled={!isEditMode}
                      styles={styles.selectStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} mr={2} ml={3}>
                    <label
                      htmlFor="AuthorizedMobileNumber"
                      className="form-label"
                      style={{ color: "#2b4257" }}
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="AuthorizedMobileNumber"
                      name="AuthorizedMobileNumber"
                      style={
                        isEditMode ? styles.editableField : styles.readOnlyField
                      }
                      value={values.AuthorizedMobileNumber || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      placeholder="Mobile Number"
                      readOnly={!isEditMode}
                      onInput={(e) => {
                        // Use a regular expression to remove non-numeric characters
                        e.target.value = e.target.value.replace(/\D/g, "");
                        handleChange(e);
                      }}
                    />
                    {errors.AuthorizedMobileNumber &&
                      touched.AuthorizedMobileNumber ? (
                      <p className="form-error error-message">
                        {errors.AuthorizedMobileNumber}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} mt={4} mb={2} style={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={12} md={8} lg={8} style={{ textAlign: "center" }}>
                    {isEditMode ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          width: "85%",
                          maxWidth: "500px",
                          background: "linear-gradient(90deg, #625DBD 0%, #2B4257 100%)",
                          color: "white",
                          borderRadius: "20px",
                          padding: "12px",
                          fontSize: "16px",
                          marginTop: "5%",
                        }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          width: "85%",
                          maxWidth: "500px",
                          background: "linear-gradient(90deg, #2B4257 0%, #625DBD 100%)",
                          color: "white",
                          borderRadius: "20px",
                          padding: "12px",
                          fontSize: "16px",
                          marginTop: "5%",
                        }}
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit
                      </button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
