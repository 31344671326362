import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { patchAdminPaymentsAction,getadminPaymentsAction } from '../../../store/actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './payments.css';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import subscriptionTypes from '../../../utils/subscriptiontypes';

const UserPayment = ({ paymentData,handleCloseUserPayment }) => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(true);

  const validationSchema = Yup.object().shape({
    payment_amount: Yup.number().required('Payment amount is required'),
    is_success: Yup.boolean().required('Success status is required'),
    subscription: Yup.number().required('Subscription type is required'),
    payment_date: Yup.date().required('Payment date is required'),
    transaction_id: Yup.string().required('Transaction ID is required'),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      payment_amount: paymentData ? paymentData.payment_amount : '',
      is_success: paymentData ? paymentData.is_success : false,
      subscription: paymentData ? paymentData.subscription : '',
      payment_date: paymentData ? paymentData.payment_date?.slice(0, 10) : '',
      transaction_id: paymentData ? paymentData.transaction_id : '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const updatedData = {
        payment_amount: values.payment_amount,
        is_success: values.is_success,
        subscription:parseInt(values.subscription),
        payment_date: values.payment_date,
        transaction_id: values.transaction_id,
      }
      dispatch(patchAdminPaymentsAction({ endPoint: `admin/payments/${paymentData.id}/`, ...updatedData }));
      toast.success('Payment details updated successfully');
      setEditMode(false)
      handleCloseUserPayment()
      dispatch(getadminPaymentsAction())
    },
  });

  const handleEditClick = (event) => {
    event.preventDefault();
    setEditMode(!editMode);
  };

  return (
    <div className="user-payment-container">
      <h2>Payment Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="payment_amount">Payment Amount</label>
          <input
            type="number"
            id="payment_amount"
            name="payment_amount"
            value={values.payment_amount}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!editMode}
          />
          {errors.payment_amount && touched.payment_amount ? (
            <p className="form-error" style={{ color: "red" }}>
              {errors.payment_amount}
            </p>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="is_success">Is Success</label>
          <input
            type="checkbox"
            id="is_success"
            name="is_success"
            checked={values.is_success}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!editMode}
          />
          {errors.is_success && touched.is_success ? (
            <p className="form-error" style={{ color: "red" }}>
              {errors.is_success}
            </p>
          ) : null}
          <span>{values.is_success ? 'Success' : 'Pending'}</span>
        </div>
        <div className="form-group">
          <label htmlFor="subscription">Subscription</label>
          <select
            className="value"
            id="subscription"
            name="subscription"
            disabled={!editMode}
            onChange={(e) => {
              handleChange(e);
              // Update payment_amount based on selected subscription type
              const selectedSubscription = parseInt(e.target.value);
              if (selectedSubscription === 1) {
                setFieldValue('payment_amount', 4999);
              } else if (selectedSubscription === 2) {
                setFieldValue('payment_amount', 9999);
              } else if (selectedSubscription === 3) {
                setFieldValue('payment_amount', 24999);
              }else if (selectedSubscription === 4) {
                setFieldValue('payment_amount', 50000);
              }
            }}
            onBlur={handleBlur}
            value={values.subscription}
          >
            {subscriptionTypes.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>))}
          </select>
          {errors.subscription && touched.subscription ? (
            <p className="form-error" style={{ color: "red" }}>
              {errors.subscription}
            </p>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="payment_date">Payment Date</label>
          <input
            type="date"
            id="payment_date"
            name="payment_date"
            value={values.payment_date}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!editMode}
          />
          {errors.payment_date && touched.payment_date ? (
            <p className="form-error" style={{ color: "red" }}>
              {errors.payment_date}
            </p>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="transaction_id">Transaction ID</label>
          <input
            type="text"
            id="transaction_id"
            name="transaction_id"
            value={values.transaction_id}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!editMode}
          />
          {errors.transaction_id && touched.transaction_id ? (
            <p className="form-error" style={{ color: "red" }}>
              {errors.transaction_id}
            </p>
          ) : null}
        </div>
        {editMode ? (
          <button type="submit" className="submit-btn">
            Save
          </button>
        ) : (
          <button type="button" className="edit-btn" onClick={handleEditClick}>
            Edit
          </button>
        )}
      </form>
    </div>
  );
};

export default UserPayment;


UserPayment.propTypes = {
  paymentData: PropTypes.string.isRequired,
  handleCloseUserPayment: PropTypes.string.isRequired,
};
