import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Grid, Paper, Rating, Button, Dialog, DialogTitle, DialogActions, DialogContent, Checkbox, FormControlLabel, useMediaQuery, Autocomplete, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { employeeExperienceAction, businessNameSearchAction, globalConfigAction, editEmployeeRatingAction, employeeDetailsAction, clearemployeeExperienceAction, cleareditEmployeeRatingAction } from "../../../store/actions/index";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';

const validationSchema = Yup.object({
  companyName: Yup.string()
    .required("Company name is required")
    .min(5, "Search with minimum 5 characters"),
  Designation: Yup.string().required("Designation is required"),
  start_date: Yup.date()
    .required("Start date is required")
    .max(new Date(), "Start date cannot be in the future"),
  end_date: Yup.date()
    .when('is_current', {
      is: false,
      then: (schema) => schema
        .required('End date is required')
        .min(Yup.ref('start_date'), 'End date must be after start date')
        .max(new Date(), "End date cannot be in the future"),
      otherwise: (schema) => schema.nullable()
    }),
  is_current: Yup.boolean(),
});

const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const EmployeesDashboard = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isAdding, setIsAdding] = useState(false);
  const [companyNames, setCompanyNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [designationOptions, setDesignationOptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingExperience, setEditingExperience] = useState({});
  const userDetailString = localStorage.getItem("UserDetail");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};
  const userId = userDetail.id;

  const { successData: businessNames, error, errorInfo,loading } = useSelector((state) => state.businessNamesSearchReducer);
  const { successData: globalSuccessData, } = useSelector((state) => state.globalConfigData) || {};
  const { successData: employeeDetails } = useSelector((state) => state.getEmployeeDetailsData);
  const { successData: employeeExperienceSuccess, error: EmployeeExperienceError, errorInfo: EmployeeExperienceErrorInfo } = useSelector((state) => state.employeeExperienceAction);
  const { successData: editEmployee, error: EditExperienceError, errorInfo: EditExperienceErrorInfo } = useSelector((state) => state.patchEmployeeRatingData);


  const formatDate = (dateString) => {
    if (!dateString) return "";
    return `${dateString}-01`;
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      Designation: "",
      start_date: "",
      start_month: "",
      start_year: "",
      end_date: "",
      end_month: "",
      end_year: "",
      is_current: false,
    },
    validationSchema,
    onSubmit: (values) => {
      const selectedCompany = companyNames.find((item) => item.name === values.companyName);
      const organizationId = selectedCompany ? selectedCompany.id : null;
      const payload = {
        user: userId,
        organization: organizationId,
        designation: values.Designation,
        start_date: `${values.start_year}-${values.start_month}-01`,
        end_date: values.is_current ? null : `${values.end_year}-${values.end_month}-01`,
        is_current: values.is_current,
      };

      handleSubmit(payload);
    },
  });

  useEffect(() => {
    if (globalSuccessData) {
      setDesignationOptions(globalSuccessData.employee_designation);

    }
  }, [globalSuccessData])

  const getDesignationName = (designationId, designationOptions) => {
    const designation = designationOptions.find(option => option.id === designationId);
    return designation ? designation.name : '';
  };

  const handleSubmit = (payload) => {
    dispatch(employeeExperienceAction(payload));
    setOrganizations((prev) => [...prev, payload]);
    setIsAdding(false);
  };

  const handleOpenDialog = (experience) => {
    setSelectedExperience(experience);
    setOpenEditDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenEditDialog(false);
    setSelectedExperience(null);
  };

  const handleEditPreviousExperience = (values) => {
    const payload = {
      user: userId,
      organization: values.organization,
      start_date: values.start_date,
      end_date: values.is_current ? null : formatDate(values.end_date),
      designation: values.designation,
      rating: values.rating,
      review: values.review,
      company_rating_reply: values.reply,
    };

    dispatch(editEmployeeRatingAction({
      endPoint: `employee-experience/${values.id}/`,
      ...payload
    }))
    setOpenEditDialog(false);
  };

  const handleEdit = (experience) => {
    const payload = {
      user: userId,
      organization: experience.organization,
      start_date: experience.start_date,
      end_date: `${experience.end_year}-${experience.end_month}-01`,
      designation: experience.designation,
      rating: experience.rating,
      review: experience.review
    };

    dispatch(editEmployeeRatingAction({
      endPoint: `employee-experience/${experience.id}/`,
      ...payload
    }));

    setEditMode(false);
    setEditingExperience(null);
  };


  const handleSearch = () => {
    if (searchTerm.length >= 4) {
      dispatch(businessNameSearchAction({
        endPoint: `business-autocomplete/?q=${searchTerm}`,
      }));
    }
  };

  useEffect(() => {
    if (businessNames) {
      setCompanyNames(businessNames);
    }
    if (error) {
      toast.error(errorInfo);
    }
  }, [businessNames, error, errorInfo]);
  useEffect(() => {
    if (formik.values.companyName) {
      setIsSearchDisabled(true);
    } else {
      setIsSearchDisabled(false);
    }
  }, [formik.values.companyName]);

  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(employeeDetailsAction({
      endPoint: `employee-details/${userDetail.id}`,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (employeeExperienceSuccess) {
      toast.success("Experience added successfully");
      dispatch(employeeDetailsAction({
        endPoint: `employee-details/${userDetail.id}`,
      }));
      setIsAdding(false);
      formik.resetForm();
    }
    if (EmployeeExperienceError) {
      toast.error(EmployeeExperienceErrorInfo);
    }
    return () => { dispatch(clearemployeeExperienceAction()) }

  }, [employeeExperienceSuccess, dispatch, userDetail.id, EmployeeExperienceError, clearemployeeExperienceAction]);

  useEffect(() => {
    if (editEmployee) {
      toast.success("Experience updated successfully");
      dispatch(employeeDetailsAction({
        endPoint: `employee-details/${userDetail.id}/`,
      }));
      setEditMode(false);
      setEditingExperience(null);
    }
    if (EditExperienceError) {
      toast.error(EditExperienceErrorInfo);
    }
    return () => { dispatch(cleareditEmployeeRatingAction()) }
  }, [editEmployee, dispatch, userDetail.id, EditExperienceError, cleareditEmployeeRatingAction]);

  const getNoOptionsText = () => {
    if (searchTerm.length < 5) {
      return "Type 5 or more characters to search";
    }
    if(businessNames?.length === 0){
      return "No results found";
    }
    if(loading){
      return "Loading...";
    }
  }

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 100; year--) {
      years.push({ value: year.toString(), label: year.toString() });
    }
    return years;
  };

  return (
    <Box sx={{ minHeight: "60vh" }}>
      <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", borderRadius: 2, boxShadow: 2, width: { xs: '150%', sm: '100%', md: '79%' }, padding: 3, marginLeft: { xs: '-30%', sm: '-12%', md: '10%' } }}>
        {isAdding && (
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ padding: 3, backgroundColor: "#f8f9fa" }}>
            <Autocomplete
              fullWidth
              id="companyName"
              options={searchTerm.length >= 4 ? (companyNames || []) : []}
              getOptionLabel={(option) => option?.name || ''}
              value={formik.values.companyName ? { name: formik.values.companyName } : null}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                formik.setFieldValue('companyName', newValue ? newValue.name : '');
              }}
              onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
                handleSearch();
              }}
              noOptionsText={getNoOptionsText()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={isSearchDisabled ? "Company selected" : "Search Company Name"}
                  margin="normal"
                  size="small"
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              )}
            />


            {/* Rest of the form fields remain the same */}
            <TextField
              name="Designation"
              fullWidth
              select
              SelectProps={{ native: true }}
              value={formik.values.Designation}
              onChange={formik.handleChange}
              InputProps={{ shrink: "true" }}
              onBlur={formik.handleBlur}
              error={formik.touched.Designation && Boolean(formik.errors.Designation)}
              helperText={formik.touched.Designation && formik.errors.Designation}
              sx={{
                width: '100%',
                borderRadius: '5px',
                '& .MuiInputBase-root': { paddingRight: '8px' },
                '& .MuiInputBase-input': { padding: '4px 6px' },
              }}
            >
              <option value="" disabled>Select Designation</option>
              {globalSuccessData?.employee_designation?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </TextField>

            <Box sx={{ display: 'flex', gap: 2, mt: '2%' }}>
              <FormControl fullWidth size="small">
                <InputLabel>Start Month</InputLabel>
                <Select
                  value={formik.values.start_month || ''}
                  label="Start Month"
                  onChange={(e) => {
                    formik.setFieldValue('start_month', e.target.value);
                    formik.setFieldValue('start_date',
                      `${formik.values.start_year || new Date().getFullYear()}-${e.target.value}`);
                  }}
                  error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel>Start Year</InputLabel>
                <Select
                  value={formik.values.start_year || ''}
                  label="Start Year"
                  onChange={(e) => {
                    formik.setFieldValue('start_year', e.target.value);
                    formik.setFieldValue('start_date',
                      `${e.target.value}-${formik.values.start_month || '01'}`);
                  }}
                  error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {generateYearOptions().map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.is_current}
                    onChange={formik.handleChange}
                    name="is_current"
                  />
                }
                label="Is current organization?"
              />
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>End Month</InputLabel>
                <Select
                  value={formik.values.end_month || ''}
                  label="End Month"
                  disabled={formik.values.is_current}
                  onChange={(e) => {
                    formik.setFieldValue('end_month', e.target.value);
                    formik.setFieldValue('end_date',
                      `${formik.values.end_year || new Date().getFullYear()}-${e.target.value}`);
                  }}
                  error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel>End Year</InputLabel>
                <Select
                  value={formik.values.end_year || ''}
                  label="End Year"
                  disabled={formik.values.is_current}
                  onChange={(e) => {
                    formik.setFieldValue('end_year', e.target.value);
                    formik.setFieldValue('end_date',
                      `${e.target.value}-${formik.values.end_month || '01'}`);
                  }}
                  error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {generateYearOptions().map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>


            <Box sx={{ display: 'flex', gap: 4, justifyContent: "flex-end", mt: '5%' }}>
              <Button variant="outlined" onClick={() => setIsAdding(false)} sx={{ textTransform: "none" }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{ backgroundColor: "#65A3DD", textTransform: "none" }}>
                Save Changes
              </Button>
            </Box>
          </Box>
        )}

        {editMode && editingExperience && (
          <Box component="form" sx={{ padding: 3, backgroundColor: "#fff" }}>
            <TextField
              fullWidth
              value={getDesignationName(editingExperience.designation, designationOptions)}
              margin="normal"
              size="small"
              label="Designation"
              InputProps={{
                readOnly: true,
                sx: { color: '#5d5b5b', backgroundColor: '#f8f9fa' }
              }}
            />

            <TextField
              fullWidth
              label="Start Date"
              value={new Date(editingExperience.start_date).toLocaleString('default', { month: 'short' }) + '-' +
                new Date(editingExperience.start_date).getFullYear()}
              InputProps={{
                readOnly: true,
                sx: { color: '#5d5b5b', backgroundColor: '#f8f9fa' }
              }}
              margin="normal"
              size="small"
            />

            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editingExperience.is_current}
                    onChange={(e) => {
                      setEditingExperience({
                        ...editingExperience,
                        is_current: e.target.checked
                      });
                    }}
                    name="is_current"
                  />
                }
                label="Is current organization?"
              />
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>End Month</InputLabel>
                <Select
                  value={editingExperience.end_month || ''}
                  label="End Month"
                  disabled={editingExperience.is_current}
                  onChange={(e) => {
                    setEditingExperience({
                      ...editingExperience,
                      end_month: e.target.value,
                      end_date: `${editingExperience.end_year || new Date().getFullYear()}-${e.target.value}`
                    });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel>End Year</InputLabel>
                <Select
                  value={editingExperience.end_year || ''}
                  label="End Year"
                  disabled={editingExperience.is_current}
                  onChange={(e) => {
                    setEditingExperience({
                      ...editingExperience,
                      end_year: e.target.value,
                      end_date: `${e.target.value}-${editingExperience.end_month || '01'}`
                    });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // This will make it scrollable after ~10 items
                      },
                    },
                  }}
                >
                  {generateYearOptions().map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 3, display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleEdit(editingExperience)}
                sx={{ backgroundColor: "#65A3DD" }}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        )}

        {/* For Current Experience Section */}
        {(!employeeDetails?.current_experience && (!employeeDetails?.previous_experiences || employeeDetails.previous_experiences.length === 0)) ? (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            width: isMobile ? "350px" : "75%",
            padding: 3,
            marginLeft: isMobile ? "0%" : "10%"
          }}>
            <Typography variant="h6" sx={{ color: "#65A3DD", marginBottom: 2 }}>
              Work Experience
            </Typography>
            <Typography variant="body1" color="text.secondary" textAlign="center">
              No work experience details added yet. Add your current and previous work experiences.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2%' }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#65A3DD", width: { xs: '80%', sm: '50%', md: '100%' }, textTransform: "none" }}
                onClick={() => setIsAdding(true)}
              >
                Add Your Experience
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            {employeeDetails?.current_experience ? (
              <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: { sm: 'center', xs: "flex-start", md: 'center' },
                ml: { xs: '0%', sm: '10%', md: '0%' },
              }}>
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '79%' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, marginTop: "15px", marginBottom: "15px" }}>
                    <Typography sx={{ fontWeight: "600", fontSize: isMobile ? "18px" : "25px", color: '#000' }}>
                      Current Organization Details
                    </Typography>
                    <Box sx={{ display: 'flex', flexBasis: { xs: 'column', md: 'column', sm: 'row' } }}>
                      <EditIcon
                        sx={{
                          cursor: 'pointer',
                          color: '#65A3DD',
                          '&:hover': { color: '#1584D4' },
                          position: 'relative',
                          top: { sm: '0', md: '0px', xs: '-40px' },
                          left: { sm: '0', md: '0%', xs: '100px' },
                        }}
                        onClick={() => {
                          setEditMode(true);
                          setEditingExperience(employeeDetails?.current_experience);
                        }}
                      />
                      <Box sx={{ marginLeft: { xs: '-30%', sm: '10%', md: '10%' }, display: 'flex', flexDirection: 'row', gap: '2%', mt: { xs: '-10%', sm: '0', md: '0' } }}>
                        {employeeDetails?.current_experience.is_verified ? (
                          <>
                            <VerifiedIcon sx={{ color: "green" }} />
                            <Typography sx={{ color: 'green', fontWeight: 'bold' }}>Verified</Typography>
                          </>
                        ) : (
                          <>
                            <VerifiedIcon sx={{ color: "red" }} />
                            <Typography sx={{ color: 'red', fontWeight: 'bold', mr: { xs: '-10%', sm: '-20%', md: '-50%' } }}>Not Verified</Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                    gap: "15px",
                    padding: "0 20px",
                  }}>
                    <Box>
                      <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Company Name</Typography>
                      <Typography color='#000'>{employeeDetails?.current_experience.business_name}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Designation</Typography>
                      <Typography color='#000'>{getDesignationName(employeeDetails?.current_experience.designation, designationOptions)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Work Period</Typography>
                      <Typography variant="caption" color='#000'>
                        {`${new Date(employeeDetails?.current_experience.start_date).toLocaleString('default', { month: 'short' })}-${new Date(employeeDetails?.current_experience.start_date).getFullYear()} to Present`}
                      </Typography>
                    </Box>
                    {employeeDetails?.current_experience.is_verified ? (
                      <>
                        <Box>
                          <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Rating</Typography>
                          <Rating value={parseInt(employeeDetails?.current_experience.rating)} readOnly size="small" />
                        </Box>
                        <Box sx={{ gridColumn: isMobile ? "1" : "1 / span 2" }}>
                          <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Remarks</Typography>
                          <Box>
                            {employeeDetails?.current_experience?.review?.length > 1 ? (
                              <Typography color='#000'>{employeeDetails?.current_experience.review}</Typography>
                            ) : "No Remarks"}
                          </Box>
                        </Box>
                      </>
                    ) : ""}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                width: isMobile ? "350px" : "75%",
                padding: 3,
                marginLeft: isMobile ? "0%" : "10%"
              }}>
                <Typography variant="h6" sx={{ color: "#65A3DD", marginBottom: 2 }}>
                  Current Organization Details
                </Typography>
                <Typography variant="body1" color="text.secondary" textAlign="center">
                  {employeeDetails?.current_experience ?
                    "Your Current Organization is pending verification. Please wait for the verification process to complete." :
                    "No current organization details added yet. Add your current organization details ."}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#65A3DD", width: { xs: '80%', sm: '50%', md: '40%' }, textTransform: "none" }}
                onClick={() => setIsAdding(true)}
              >
                Add New Experience
              </Button>
            </Box>
            {employeeDetails?.previous_experiences && employeeDetails.previous_experiences.length > 0 ? (
              <Paper elevation={0} sx={{
                mt: '5%',
                width: '80%',
                pb: '5%',
                mb: '5%',
                pt: '2%',
                mx: 'auto',
              }}>
                <Typography variant="h5" sx={{
                  fontWeight: 'bold',
                  marginBottom: 3,
                  background: '#65A3DD',
                  width: { xs: '100%', sm: '50%' },
                  p: 1,
                  color: 'white',
                }}>
                  Previous Experiences
                </Typography>
                <Grid container spacing={2} gap={4} mt={2} width={{ xs: '100%', sm: '100%', md: '100%' }}
                  sx={{ justifyContent: 'center' }}>
                  {employeeDetails?.previous_experiences.map((review) => (
                    <Grid item xs={22} sm={12} md={6} key={review.id} textAlign={'center'} sx={{ ml: -2, borderRadius: 1, boxShadow: 1, padding: 2 }}>
                      <Box sx={{
                        marginBottom: 1,
                        marginRight: { sm: '0%', md: '0%', xs: '9%' },
                        width: '100%',
                      }}>
                        {review.is_verified ? (
                          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box sx={{ display: 'flex', flexDirection: 'row', mb: '2%' }}>
                                <VerifiedIcon sx={{ color: "green", fontSize: '16px' }} />
                                <Typography variant='h6' sx={{ color: 'green', fontWeight: 'bold', fontSize: '10px' }}>Verified</Typography>
                              </Box>
                              {!review?.exit_verified && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: '2%' }}>
                                  <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>Exit not Verified</Typography>
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                cursor: 'pointer',
                                color: '#1584D4',
                                alignSelf: 'flex-end',
                                mt: '-12%',
                                mr: '2%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}
                              onClick={() => handleOpenDialog(review)}
                            >Reply</Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex' }}>
                            <VerifiedIcon sx={{ color: "red", fontSize: '16px' }} />
                            <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px', marginRight: '-5%' }}>Not Verified</Typography>
                          </Box>
                        )}

                      </Box>
                      <Box>
                        <Typography variant="body1" color='#000'>{review?.business_name}</Typography>
                        <Typography variant="body2" color="#000" sx={{ fontfamily: 'Inter' }}>
                          {getDesignationName(review?.designation, designationOptions)}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                          {review?.title}
                        </Typography>
                        <Typography variant="caption" fontWeight='bold' color='#000'>
                          {`${new Date(review?.start_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.start_date).getFullYear()} to ${new Date(review?.end_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.end_date).getFullYear()}`}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                          <Box sx={{ fontWeight: 'bold', fontFamily: 'Inter' }}>
                            {review.is_verified && (
                              <>
                                <Rating value={parseInt(review?.rating)} readOnly />
                                {review?.review && (
                                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1 }}>
                                    <Typography component="span" sx={{ color: "#65A3DD", fontWeight: 'bold' }}>
                                      Review:
                                    </Typography>
                                    <Typography component="span" color="#000" sx={{ wordBreak: 'break-word', lineHeight: 1.5 }}>
                                      {review?.review}
                                    </Typography>
                                  </Box>
                                )}
                                {review?.company_rating_reply && (
                                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1 }}>
                                    <Typography component="span" sx={{ color: "#65A3DD", fontWeight: 'bold' }}>
                                      Response:
                                    </Typography>
                                    <Typography component="span" color="#000" sx={{ wordBreak: 'break-word', lineHeight: 1.5 }}>
                                      {review?.company_rating_reply}
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Paper>

            ) : (
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                width: isMobile ? "100%" : "75%",
                padding: 3,
                marginLeft: isMobile ? "0%" : "10%"
              }}>
                <Typography variant="h6" sx={{ color: "#65A3DD", marginBottom: 2 }}>
                  Previous Experiences
                </Typography>
                <Typography variant="body1" color="text.secondary" textAlign="center">
                  {employeeDetails?.previous_experiences &&
                    employeeDetails.previous_experiences.length > 0 ?
                    "Your Previous Experiences are pending verification. Please wait for the verification process to complete." :
                    "No previous Experiences details added yet. Add your work experience."}
                </Typography>
              </Box>
            )
            }
          </>
        )}
        <Dialog
          open={openEditDialog}
          onClose={handleCloseDialog}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            sx: {
              minHeight: '400px',
              maxHeight: '500px',
              width: 'auto'
            }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>Edit Experience</DialogTitle>
          <DialogContent sx={{ pt: 1 }}>
            {selectedExperience && (
              <Box component="form" sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography color='#65A3DD'>CompanyName: </Typography>
                  <Typography color='#000'>{selectedExperience.business_name}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Typography color='#65A3DD'>Designation:  </Typography>
                  <Typography color='#000'>{getDesignationName(selectedExperience.designation, designationOptions)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Typography color='#65A3DD'>Period:</Typography>
                  <Typography color='#000' fontSize={15}>
                    {`${new Date(selectedExperience.start_date).toLocaleString('default', { month: 'short' })}-${new Date(selectedExperience.start_date).getFullYear()} to ${new Date(selectedExperience.end_date).toLocaleString('default', { month: 'short' })}-${new Date(selectedExperience.end_date).getFullYear()}`}
                  </Typography>
                </Box>

                <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                  <Typography color='#65A3DD'>Rating:</Typography>
                  <Rating value={parseInt(selectedExperience.rating)} readOnly size="small" />
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Typography color="#65A3DD">Remarks:</Typography>
                  <Typography color='black'>
                    {selectedExperience.review}
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  size="small"
                  label="Response for rating"
                  multiline
                  rows={2}
                  margin="dense"
                  sx={{ mt: 2 }}
                  value={selectedExperience.reply || ''}
                  onChange={(e) => setSelectedExperience({ ...selectedExperience, reply: e.target.value })}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} size="small">Cancel</Button>
            <Button
              onClick={() => handleEditPreviousExperience(selectedExperience)}
              variant="contained"
              size="small"
              sx={{ backgroundColor: "#65A3DD" }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>


      </Box >
    </Box >
  )
}
export default EmployeesDashboard;
