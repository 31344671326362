import React from 'react';
import { AppBar, Toolbar, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import clearbiz3 from '../../assets/clearbiz3.png'; 
import { Link } from 'react-router-dom';

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const getLogoWidth = () => {
    if (isMobile) return '150px';
    if (isTablet) return '175px';
    return '200px';
  };

  const logoStyle = {
    width: getLogoWidth(),
    marginRight: 'auto',
    height: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
      <Toolbar sx={{ padding: isMobile ? '10px' : '20px' }}>
        <img 
         srcSet={`${clearbiz3} 600w, ${clearbiz3} 1200w`}
         sizes="(max-width: 600px) 100vw, 50vw"
         src={clearbiz3} alt="ClearBiz Logo" style={logoStyle} />
        
        {!isMobile && (
        <Box sx={{fontSize:{xs:'12px',sm:'18px'},marginRight:{xs:'5px',sm:'30px'}}}>
          <Link to="/" style={{color:'#2b4257',marginRight:'15px',fontWeight:'600' }}>Home</Link>
          <Link to="/walloftrust" style={{color:'#2b4257',marginRight:'5px',fontWeight:'600' }}>Wall of Trust</Link>
        </Box>
      )}
      <Box sx={{fontSize:{xs:'12px',sm:'18px'},marginRight:{xs:'5px',sm:'30px'},display:{xs:'block',sm:'none'}}}>
          <Link to="/walloftrust" style={{color:'#2b4257',marginRight:'5px',fontWeight:'600' }}>Wall of Trust</Link>
        </Box>
      
        <Link to="/Login" style={{ textDecoration: 'none' }}>
          <Button 
            style={{ 
              backgroundColor: '#2b4257', 
              color: 'white',
              borderRadius: '30px',
              height: {xs:'20px',sm:'40px'},
              fontSize:{xs:'8px',sm:'16px'},
              '&:hover': {
                backgroundColor: '#005f8f',
              },
            }}
          >
            Login/SignUp
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;