import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import './navbar.scss'
import clearbiz3 from "../../assets/clearbiz3.png"
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const CustomTypography = styled(Typography)``;

  return (
      <header id="header" className="d-flex align-items-center justify-content-between pl-3 pr-3">
        <CustomTypography variant="h6" noWrap component="div" className='customtypography' >
          {/* <img src={clearbiz1} style={{ width: isMobile ? '130px' : '200px' }} alt="ClearBiz Logo" /> */}
          <Link to="/">
            <img src={clearbiz3} style={{ maxWidth: isMobile ? '130px' : '200px', height: 'auto',marginBottom:'9%' }} alt="ClearBiz Logo" />
          </Link>
        </CustomTypography>
  
        <nav id="navbar" className="navbar">
          <ul>
            <li><NavLink exact="true"  className="nav-link scrollto" to="/">Home</NavLink></li>
            <li><Link className="nav-link scrollto" to="/#about">About</Link></li>
            <li><Link className="nav-link scrollto" to="/Login">Login</Link></li>
            {/* <li><Link className="nav-link scrollto" to="/walloftrust">Wall of Trust</Link></li> */}
            {/* <li><NavLink className="nav-link scrollto" to="/">Login/SignUp</NavLink></li> */}
          </ul>
        </nav>
      </header>

  )
}

export default Navbar;