import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Paper,
  Modal,
} from "@mui/material";
import HomePageFooter from "./Homepage/HomePageFooter";
import Termsandpolicy from "./terms_policy";
import Navbar from "./navbar";
import { globalConfigAction, getTestimonialsAction } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/signup.scss";

export default function WallofTrust() {
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [prefferedData, setPrefferedData] = useState([]);
  const { successData: globalConfigData } =
    useSelector((state) => state.globalConfigData) || {};
  const { successData: testimonialsData } =
    useSelector((state) => state.getTestimonialsReducer) || {};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalConfigAction());
    dispatch(getTestimonialsAction());
  }, [dispatch]);

  const handleShowMore = () => {
    console.log("show more clicked");
    setOpenLogoModal(true);
  };
  const handleCloseLogoModal = () => {
    setOpenLogoModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (testimonialsData) {
      const sortedData = [...testimonialsData].sort(
        (a, b) => b.is_preferred - a.is_preferred
      );
      setPrefferedData(sortedData);
    }
  }, [testimonialsData]);

  return (
    <Box>
      <Navbar />
      {/* Trusted By Header */}
      <Typography
        variant="h4"
        color={"#2b4257"}
        sx={{ mt: 5, ml: 5, fontWeight: "bold" }}
      >
        Trusted by
      </Typography>

      {/* Accountability Cards */}
      {/* <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={20}
        slidesPerView={3}
        loop={true}
        navigation={true}
        pagination={{
          el: ".custom-swiper-pagination",
          clickable: true,
          type: "bullets",
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        style={{ maxWidth: "90%", margin: "0 auto", marginBottom: "5%" }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
        }}
        onSlideChange={(swiper) => {
          const slides = swiper.slides;
          slides.forEach((slide, index) => {
            if (index === swiper.activeIndex + 1) {
              slide.style.transform = "scale(1.0)";
            } else {
              slide.style.transform = "scale(0.8)";
            }
          });
        }}
      >
        {prefferedData?.map((item) => (
          <SwiperSlide key={item.id}>
            <Card
              sx={{
                minHeight: "420px",
                height: "400px",
                margin: "20px",
                padding: "16px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Box
                sx={{
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.company_logo && (
                  <img
                    src={item.company_logo}
                    alt={item.author_company_name}
                    style={{
                      width: "140px",
                      height: "140px",
                      minWidth: "140px",
                      minHeight: "140px",
                      objectFit: "contain",
                      marginBottom: "5px",
                    }}
                  />
                )}
              </Box>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
                variant="h6"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  mb: 5,
                  minHeight: "100px",
                  fontSize: "0.7rem",
                }}
              >
                {item.content}
              </Typography>
              <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                <Typography variant="h6">{item.author_name}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {item.author_position}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {item.author_company_name}
                </Typography>
              </Box>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper> */}
      <div className="custom-swiper-pagination"></div>
      {/* Logos Section */}
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          margin: "50px",
        }}
      >
        {globalConfigData?.logos?.seed?.slice(1, 12).map((logoUrl, index) => (
          <Grid
            item
            xs={4}
            md={2}
            sm={2}
            key={`logo-${logoUrl}`}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              order: index < 6 ? 0 : 1,
              padding: {
                xs: "5px",
                sm: "30px",
              },
              display: {
                xs: index < 6 ? "flex" : "none",
                sm: "flex",
              },
            }}
          >
            <img
              src={logoUrl}
              alt={`Logo ${index + 1}`}
              style={{
                width: "100%",
                maxWidth: "160px",
                height: "60px",
                objectFit: "contain",
              }}
            />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            order: 1,
            marginTop: "-2%",
            marginLeft: "-2%",
          }}
        >
          <Button
            onClick={handleShowMore}
            sx={{
              color: "#000",
              fontSize: { xs: "1rem", sm: "0.9", md: "1rem" },
              fontWeight: 500,
              fontFamily: "Georgia, serif",
              border: "1px solid #000",
            }}
          >
            Show More
          </Button>
        </Grid>
      </Grid>

      {/* Founder's Message */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          elevation={2}
          sx={{
            padding: { xs: "20px", sm: "40px" },
            margin: "5%",
            width: "80%",
            borderRadius: "8px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Founder's Message
          </Typography>
          <Typography
            variant="body1"
            color="#000"
            sx={{
              lineHeight: 1.8,
              fontSize: "1rem",
              margin: "20px 0",
              display: { xs: "none", sm: "block" },
            }}
          >
            The inspiration behind ClearBiz came from the lack of transparency
            in B2B transactions and the absence of a platform that connects and
            facilitates meaningful communication between businesses. With
            ClearBiz, my vision is to create an AI-enabled platform that
            empowers businesses to make informed decisions.
            <br />
            <br />
            By staying updated on existing partners and gaining insights about
            new ones, businesses can enhance their debt recovery processes and
            foster stronger relationships.
            <br />
            <br />
            At ClearBiz, we are guided by trust-building, privacy, and
            unwavering commitment to our promises. Our goal is to revolutionize
            the B2B space through data-driven models that provide clarity and
            build confidence in every interaction.
            <br />
            <br />I extend my heartfelt gratitude to our customers, team, and
            partners for their trust and collaboration as we continue to drive
            innovation in the B2B ecosystem.
          </Typography>
          <Typography
            variant="body1"
            color="#000"
            sx={{
              lineHeight: 1.2,
              fontSize: "1rem",
              margin: "15px 0",
              display: { xs: "block", sm: "none" },
            }}
          >
            ClearBiz was inspired by the need for transparency in B2B
            transactions. Our AI-powered platform empowers businesses to make
            data-driven decisions, improve debt recovery, and build trust.
            Guided by our values of privacy, trust-building, and commitment, we
            aim to revolutionize the B2B space.
          </Typography>
          <Box sx={{ textAlign: "right", marginTop: "20px" }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#000",
              }}
            >
              NALAM VIGNAN
            </Typography>
            <Typography
              variant="body2"
              color="green"
              sx={{
                fontSize: "0.9rem",
                marginTop: "5px",
              }}
            >
              Founder, ClearBiz
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "20%" }}>
        <HomePageFooter />
        <Termsandpolicy />
      </Box>
      <Modal
        open={openLogoModal}
        onClose={handleCloseLogoModal}
        aria-labelledby="logo-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "70%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 6,
            maxHeight: "75vh",
            overflowY: "auto",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 3, fontWeight: "bold", color: "#2b4257" }}
          >
            Trusted Partners
          </Typography>
          <Grid container spacing={3}>
            {globalConfigData?.logos?.seed?.slice(1).map((logoUrl, index) => (
              <Grid item xs={6} sm={4} md={3} key={`modal-logo-${index}`}>
                <img
                  src={logoUrl}
                  alt={`Logo ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
