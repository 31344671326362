import {BiUpArrowAlt, BiDownArrowAlt} from 'react-icons/bi'
import React from "react";
import industryTypes from './industrytypes';


const getColor = (raise_or_fall_percent, is_raised) => {
  if (raise_or_fall_percent === "0.00") {
    return 'grey';
  }
  return is_raised ? 'green' : 'red';
};

const data = {
    
    myPartners: [
       
        {
            Header: 'Name',
            accessor: 'partner_business_name',
            
           
        },
        {
            Header: 'Industry',
            accessor: 'industry',          
        },        
        {
            Header: 'Partner Type',
            accessor: 'partner_type',          
        }, 
        {
            Header: 'Location',
            accessor: 'location',          
        },  
        
        {
            Header: 'My Rating',
            accessor: 'my_rating',          
        }   ,
        {
            Header: 'Total Rating',
            accessor: 'avg_rating',          
        }, 
        {
            Header: 'Latest Update',
            accessor: 'raise_or_fall_percent',
            Cell: row => {
             const is_raised = row.row.original.is_rised;
             const raise_or_fall_percent = parseFloat(row.row.original.raise_or_fall_percent).toFixed(2);
    return (
              <span style={{color: getColor(raise_or_fall_percent, is_raised)}}>
                 {raise_or_fall_percent} {raise_or_fall_percent !== "0.00" && (is_raised ? <BiUpArrowAlt/> : <BiDownArrowAlt/>)}
                  </span> 
    );
}

            
        }   ,
        {
            Header: 'Action View/Edit',
            accessor: 'actions1', 
           
        }  , 
       
    ],
    serachDB: [
       
        {
            Header: 'Name',
            accessor: 'user.business.name',
            
           
        },
        {
            Header: 'Industry',
            accessor: 'user.business.industry',
            Cell: row => {
                const industryId = row.row.original.user.business.industry;
                const industryLabel = industryTypes.find(type => type.id === industryId)?.label || industryId;
                return <span>{industryLabel}</span>;
            },
        },    
        {
            Header: 'Location',
            accessor: 'user.business.district',          
        },  
        {
            Header: 'Total Rating',
            accessor: 'avg_rating',          
        }, 
//         {
//             Header: 'Latest Update',
//             accessor: 'score_details.raise_or_fall_percent',
//             Cell: row => {
//              const is_raised = row.row.original.score_details.is_raised;
//              const raise_or_fall_percent = parseFloat(row.row.original.score_details.raise_or_fall_percent).toFixed(2);
//     return (
//              <span style={{color: getColor(raise_or_fall_percent, is_raised)}}>
//                  {raise_or_fall_percent} {raise_or_fall_percent !== "0.00" && (is_raised ? <BiUpArrowAlt/> : <BiDownArrowAlt/>)}
//                   </span> 
//     );
// }

            
//         }   ,
        {
            Header: 'Action',
            accessor: 'view', 
           
        }  , 
       
    ],
    inActiveUsers: [
       
        {
            Header: 'Business Name',
            accessor: 'business.name',
            
           
        }, 
        {
            Header: 'Location',
            accessor: 'business.district',          
        },  
        {
            Header: 'Mobile',
            accessor: 'phone_number',          
        }, 
        {
            Header: 'Email',
            accessor: 'email', 
           
        },
    {
  Header: 'Authorized User / MobileNumber',
  accessor: row =>
    {   
    const userName = row.business?.authorized_user_name ??'NA';
    const mobileNumber = row.business?.authorized_user_mobilenumber ??'NA';
    
    return `${userName} / ${mobileNumber}`;
  },
},
{
    Header: 'Action',
    accessor: 'remainder',

},
    ],
    myRatings: [
       
        {
            Header: 'Partner Name / Review',
            accessor: 'business_partner_main.business.name',
            Cell: row => {
                const partner_name = row.row.original.partner_business_name
                const review = row.row.original.review
                return (
                    <div> 
                        <p>{review === '' ? partner_name : `${partner_name} says`}</p>
                        <h6 style={{fontWeight: 'normal', color: 'black'}}>{review}</h6>
                     </div>
                )
            }   
        },
        {
            Header: 'Ratings',
            accessor: 'score',          
        },        
        {
            Header: 'Date',
            accessor: 'Date',    
            Cell: row => {
                const date = new Date(row.row.original.Date);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                const humanFriendlyDate = date.toLocaleDateString(undefined, options);
                return (
                    <div> 
                        {humanFriendlyDate}
                     </div>
                )
            }        
        }, 
        {
            Header: 'Action',
            accessor: 'myRatingActions',
        }
    ],

    
    raisedByYou: [
       
        {
            Header: 'Raised on user',
            accessor: 'raised_on_user.first_name',
        },
        {
            Header: 'Date',
            accessor: 'raised_on',      
            Cell: row => {
                const date = new Date(row.row.original.raised_on);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                const humanFriendlyDate = date.toLocaleDateString(undefined, options);
                return (
                    <div> 
                        {humanFriendlyDate}
                     </div>
                )
            } 
        },        
        {
            Header: 'Reason',
            accessor: 'reason',          
        }, 
        {
            Header: 'Status',
            accessor: 'is_resolved',      
            Cell: row => {
                const status = new Date(row.row.original.is_resolved);
                const statusLable = status ? 'Resolved' : 'Pending';
                return (
                    <div> 
                        {statusLable}
                     </div>
                )
            }      
        }
    ],
    raisedOnYou: [
        {
            Header: 'Raised by user',
            accessor: 'user.first_name',
        },
        {
            Header: 'Date',
            accessor: 'raised_on',         
            Cell: row => {
                const date = new Date(row.row.original.raised_on);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                const humanFriendlyDate = date.toLocaleDateString(undefined, options);
                return (
                    <div> 
                        {humanFriendlyDate}
                     </div>
                )
            }  
        },        
        {
            Header: 'Reason',
            accessor: 'reason',          
        }, 
        {
            Header: 'Action' ,
            accessor: 'myResolvedActions',          
        }
    ],
    
    payments:[
       
        {
            Header: 'Business Name',
            accessor: 'business',
            
           
        },
        {
            Header: 'Amount',
            accessor: 'payment_amount',
        },    
        {
            Header: 'StatusSubscription Type',
            accessor: 'subscription',          
        }, 
        
        {
            Header: 'Status',
            accessor: 'is_success',
            Cell: row => {
                const status = new Date(row.row.original.is_success);
                const statusLable = status ? 'Success' : 'Pending';
                return (
                    <div> 
                        {statusLable}
                     </div>
                )
            } 
        }, 

        {
            Header: 'Action',
            accessor: 'paymentsAction', 
           
        }  , 
    
    ],

    recievedRatings:[
        {
            Header: 'BusinessName',
            accessor: 'business_partner_main.business.name', 
        },
        {
            Header: 'Indusrty',
            accessor: 'business_partner_main.business.industry.name',
        },
        {
            Header: 'Location',
            accessor: 'business_partner_main.business.state',          
        }, 

        {
            Header: 'score',
            accessor: 'score',          
        }, 
        
        {
            Header: 'Action',
            accessor: 'recievedRatingsAction', 
           
        }  ,
    
    ],

    givenRatings:[
        {
            Header: 'BusinessName',
            accessor: 'business_partner_assoc.business.name',
            
           
        },
        {
            Header: 'IndustryType',
            accessor: 'business_partner_assoc.business.industry.name',
        }, 
        {
            Header: 'Location',
            accessor: 'business_partner_assoc.business.state',          
        },     
        {
            Header: 'Score',
            accessor: 'score',          
        },
        {
            Header: 'Action',
            accessor: 'givenRatingsAction',

        }
    
    ],
    reletedPan: [
       {
            Header: 'User Name',
            accessor: 'main_user_business_name',          
        } ,
         {
            Header: 'Relation Name',
            accessor: 'name',          
        },
        {
            Header: 'Relation Pan',
            accessor: 'user_pan_assoc',          
        }, 
       
        {
            Header: 'Relation ',
            accessor: "relationship", 
           
        }, 
        {
            Header: 'Linked',
            accessor: "isValid", 
           
        }, 
        {
            Header: 'Action',
            accessor: 'editLinkedPartner', 
           
        } 
       
         
    ],

    LinkedRealationships: [
        {
             Header: 'Id',
             accessor: 'id',          
         },
         {
            Header: 'Name',
            accessor: 'name',          
        },

          {
             Header: 'PAN Number',
             accessor: 'user_pan_assoc',          
         },
        
         {
             Header: 'Relation ',
             accessor: "relationship", 
            
         }, 
          
     ],

}


export default data;
