import React, { useState, useEffect } from "react";
import { Grid,Button,Modal,Box,Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import UpdateIcon from "@mui/icons-material/Update";
import PublicIcon from "@mui/icons-material/Public";
import { useDispatch, useSelector } from "react-redux";
import { globalConfigAction } from "../../store/actions";
import "./home.css";


const OurNetwork = () => {
  const dispatch = useDispatch();
  const [logos, setLogos] = useState([]);
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const { successData: globalConfigData } = useSelector(state => state.globalConfigData) || {};



  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (globalConfigData) {
      setLogos(globalConfigData.logos.seed.slice(1));
      console.log(globalConfigData.logos);
    }
  }, [globalConfigData]);

  const handleShowMore = () => {
    setOpenLogoModal(true);
  };

  const handleCloseLogoModal = () => {
    setOpenLogoModal(false);
  };

  return (
    <Grid container direction="column" sx={{ width: "100%" }}>
      <Grid
        container
        spacing={{ xs: 2, sm: 4 }}
        direction={{ xs: "column", sm: "row" }}
        sx={{
          justifyContent: "space-around",
          alignItems: { xs: "flex-start", sm: "center" },
          padding: { xs: "10% 2% 2% 2%", sm: "20px" },
          background: {
            xs: "linear-gradient(177.95deg, #FFFFFF 9.32%, rgba(185, 195, 252, 0.41) 77.36%)",
            sm: "none",
          },
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#31303b",
            fontSize: { xs: "1rem", sm: "1rem" },
            fontWeight: 500,
            fontFamily: "Georgia, serif",
            gap: "10px",
          }}
        >
          <StarBorderIcon sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }} />
          <span>Spot Threats Before They Happen</span>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#31303b",
            fontSize: { xs: "1rem", sm: "1rem" },
            fontWeight: 500,
            fontFamily: "Georgia, serif",
            gap: "10px",
          }}
        >
          <PersonSearchIcon sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }} />
          <span>Faster Debt Recovery</span>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#31303b",
            fontSize: { xs: "1rem", sm: "1rem" },
            fontWeight: 500,
            fontFamily: "Georgia, serif",
            gap: "10px",
          }}
        >
          <UpdateIcon sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }} />
          <span>Real-time Updates</span>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#31303b",
            fontSize: { xs: "1rem", sm: "1rem" },
            fontWeight: 500,
            fontFamily: "Georgia, serif",
            gap: "10px",
          }}
        >
          <PublicIcon sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }} />
          <span>Trustworthy Network</span>
        </Grid>
      </Grid>

      <Grid item sx={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontFamily: "Ruwudu",
            fontSize: { xs: "1.5rem", sm: "2rem" },
            color: "#000",
            marginBottom: "20px",
            marginTop:'20px'
          }}
        >
          Our Network
        </h2>
        {/* First row - always visible 6 images */}
        <Grid container spacing={2} sx={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px'
        }}>
          {globalConfigData?.logos?.seed?.slice(1, 12).map((logoUrl, index) => (
            <Grid item xs={4} md={2} sm={2} key={`logo-${logoUrl}`} sx={{
              justifyContent: 'center',
              alignItems: 'center',
              order: index < 6 ? 0 : 1,
              padding: {
                xs: '5px',
                sm: '30px'
              },
              display: {
                xs: index < 6 ? 'flex' : 'none',
                sm: 'flex'
              }
            }}>
              <img
                src={logoUrl}
                alt={`Logo ${index + 1}`}
                style={{
                  width: '100%',
                  maxWidth: '160px',
                  height: '60px',
                  objectFit: 'contain',
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={2} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            order: 1,
            marginTop: '-2%',
            marginLeft: '-2%',
          }}>
            <Button onClick={handleShowMore} sx={{ color: '#000', fontSize: {xs:'1rem',sm:'0.9',md:'1rem'}, fontWeight: 500, fontFamily: 'Georgia, serif',border:'1px solid #000'}}>
              Show More
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openLogoModal}
        onClose={handleCloseLogoModal}
        aria-labelledby="logo-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "70%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 6,
            maxHeight: "75vh",
            overflowY: "auto",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 3, fontWeight: "bold", color: "#2b4257" }}
          >
            Trusted Partners
          </Typography>
          <Grid container spacing={3}>
            {globalConfigData?.logos?.seed?.slice(1).map((logoUrl, index) => (
              <Grid item xs={6} sm={4} md={3} key={`modal-logo-${index}`}>
                <img
                  src={logoUrl}
                  alt={`Logo ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

export default OurNetwork;
