import React, { useEffect } from 'react';
import SideBar from "../../../components/sidebar";
import { Box, Paper, Typography, Grid, TextField, InputAdornment, Button, Checkbox, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearemployeeExperienceAction, employeeExperienceAction, globalConfigAction, } from "../../../store/actions/index";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import VerifiedIcon from '@mui/icons-material/Verified';
import { employeeEditSchema } from "./EmployeeEditSchema";
import { useFormik } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';


const StarRating = ({ rating, setRating, disabled ,formik }) => {
    const handleStarClick = (clickedStar) => {
        if (disabled) return;
        formik.setFieldValue('rating', clickedStar === rating ? clickedStar - 1 : clickedStar);
    };

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            {[1, 2, 3, 4, 5].map((star) => (
                <Typography
                    key={star}
                    onClick={() => handleStarClick(star)}
                    sx={{
                        cursor: disabled ? 'default' : 'pointer',
                        color: star <= rating ? '#FFD700' : '#D3D3D3',
                        fontSize: '2rem',
                        opacity: disabled ? 0.7 : 1,
                        '&:hover': {
                            color: !disabled && '#FFD700'
                        }
                    }}
                >
                    ★
                </Typography>
            ))}
        </Box>
    );
};


 function AddEmployeeExperience() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { successData: globalSuccessData } = useSelector((state) => state.globalConfigData) || {};
    const { successData: employeeExperienceSuccess, error, errorInfo } = useSelector((state) => state.employeeExperienceAction);

    const UserId = state?.Id;

    const userDetail = JSON.parse(localStorage.getItem("UserDetail") || "{}");
    const businessId = userDetail.business.id;

    const months = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
      ];

      const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= currentYear - 100; year--) {
          years.push({ value: year.toString(), label: year.toString() });
        }
        return years;
      };

  const formik = useFormik({
    initialValues: {
        startDate: '',
        endDate: '',
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
        rating: "",
        remarks: "",
        isVerified: true,
        isCurrentExperience: false,
    },
     validationSchema: employeeEditSchema,
    onSubmit: (values) => {   

      const payload = {
        user: UserId,
        organization: businessId,
        designation: values.designation,
        start_date: `${values.start_year}-${values.start_month}-01`,
        end_date: values.isCurrentExperience ? null : `${values.end_year}-${values.end_month}-01`,
        is_current: values.isCurrentExperience,
        rating: values.rating,
        review: values.remarks,
    };

    StarRating.propTypes = {
        rating: PropTypes.number.isRequired,
        setRating: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formik: PropTypes.object.isRequired,
    };

      console.log("Payload:", payload);
      

      handleSubmit(payload);
    },
  });

  const handleSubmit = (payload) => {
      dispatch(employeeExperienceAction(payload));
    };

    useEffect(() => {
        if (employeeExperienceSuccess) {
            toast.success("Rating Added Successfully");
            navigate('/employeeDashboard');
            dispatch(clearemployeeExperienceAction());
        }
    }, [employeeExperienceSuccess, navigate, dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(errorInfo);
        }
    }, [error, errorInfo]);

    useEffect(() => {
        dispatch(globalConfigAction());
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <SideBar />
            <Box sx={{ position: 'absolute', top: 90, right: 30 }}>
                <Button
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                    sx={{
                        backgroundColor: '#82B2C4',
                        '&:hover': {
                            backgroundColor: '#6B99A9'
                        }
                    }}
                >
                    Back
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: { xs: '15%', sm: '10%', md: '5%' } }}>
                    <Paper sx={{ width: { xs: '95%', sm: '80%', md: '60%' }, p: { xs: '20px', sm: '30px', md: '40px' }, boxShadow: '0px 0px 10.1px 0px rgba(0, 0, 0, 0.25)' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Typography variant='h4' sx={{
                                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                                fontWeight: 'bold',
                                textAlign: 'center',
                                fontFamily: 'Inter',
                                background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 4,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                Rate Employee
                                <Box onClick={() => formik.setFieldValue('isVerified', !formik.values.isVerified)} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    {formik.values.isVerified && (
                                        <VerifiedIcon sx={{
                                            color: '#4CAF50',
                                            backgroundColor: '#E8F5E9',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            fontSize: '2rem'
                                        }} />
                                    )}
                                </Box>
                            </Typography>

                            <Grid container spacing={3} mt={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                                Working Period
                                            </Typography>                          
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Start Month</InputLabel>
                                                        <Select
                                                            value={formik?.values?.start_month }
                                                            label="Start Month"
                                                            onChange={(e) => {
                                                                formik.setFieldValue('start_month', e.target.value);
                                                                formik.setFieldValue('startDate', `${formik.values.start_year || new Date().getFullYear()}-${e.target.value}`);
                                                            }}
                                                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 300,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {months.map((month) => (
                                                                <MenuItem key={month.value} value={month.value}>
                                                                    {month.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Start Year</InputLabel>
                                                        <Select
                                                            value={formik.values.start_year || ''}
                                                            label="Start Year"
                                                            onChange={(e) => {
                                                                formik.setFieldValue('start_year', e.target.value);
                                                                formik.setFieldValue('startDate', `${e.target.value}-${formik.values.start_month || '01'}`);
                                                            }}
                                                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 300,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {generateYearOptions().map((year) => (
                                                                <MenuItem key={year.value} value={year.value}>
                                                                    {year.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Checkbox
                                                            name="isCurrentExperience"
                                                            checked={formik.values.isCurrentExperience}
                                                            onChange={formik.handleChange}
                                                            sx={{
                                                                color: '#47627B',
                                                                '&.Mui-checked': {
                                                                    color: '#4A87BE',
                                                                },
                                                            }}
                                                        />
                                                        <Typography sx={{ color: '#47627B' }}>
                                                            Is This current experience
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>End Month</InputLabel>
                                                        <Select
                                                            value={formik.values.end_month || ''}
                                                            label="End Month"
                                                            disabled={formik.values.isCurrentExperience}
                                                            onChange={(e) => {
                                                                formik.setFieldValue('end_month', e.target.value);
                                                                formik.setFieldValue('endDate', `${formik.values.end_year || new Date().getFullYear()}-${e.target.value}`);
                                                            }}
                                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 300,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {months.map((month) => (
                                                                <MenuItem key={month.value} value={month.value}>
                                                                    {month.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>End Year</InputLabel>
                                                        <Select
                                                            value={formik.values.end_year || ''}
                                                            label="End Year"
                                                            disabled={formik.values.isCurrentExperience}
                                                            onChange={(e) => {
                                                                formik.setFieldValue('end_year', e.target.value);
                                                                formik.setFieldValue('endDate', `${e.target.value}-${formik.values.end_month || '01'}`);
                                                            }}
                                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 300,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {generateYearOptions().map((year) => (
                                                                <MenuItem key={year.value} value={year.value}>
                                                                    {year.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                <Grid item xs={12}>
                                    <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                        Designation
                                    </Typography>
                                    <TextField
                                        select
                                        SelectProps={{ native: true }}
                                        name="designation"
                                        value={formik.values.designation}
                                        onChange={formik.handleChange}
                                        error={formik.touched.designation && Boolean(formik.errors.designation)}
                                        helperText={formik.touched.designation && formik.errors.designation}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                fontSize: '16px',
                                                border: '1px solid',
                                                borderRadius: '5px',
                                                borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                            },
                                        }}
                                        fullWidth
                                    >
                                        <option value="" disabled>Select Designation</option>
                                        {globalSuccessData?.employee_designation?.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                        Rating
                                    </Typography>
                                    <Box>
                                        <StarRating
                                            rating={Number(formik.values.rating)}
                                            setRating={(value) => formik.setFieldValue('rating', value)}
                                        formik={formik}
                                        />
                                        {formik.touched.rating && formik.errors.rating && (
                                            <Typography color="error" variant="caption">
                                                {formik.errors.rating}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid container spacing={3} mt={3}>

                                    <Grid item xs={12} md={10}>
                                        <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                            Remarks
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={4}
                                            name="remarks"
                                            value={formik.values.remarks}
                                            onChange={formik.handleChange}
                                            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                            helperText={formik.touched.remarks && formik.errors.remarks}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    width: '100%',
                                                    fontSize: '16px',
                                                    border: '1px solid',
                                                    borderRadius: '5px',
                                                    borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                    opacity: !formik.values.isVerified ? 0.7 : 1,
                                                },
                                            }}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '5px',
                                                '& .MuiInputBase-root': { paddingRight: '8px' },
                                                '& .MuiInputBase-input': { padding: '4px 6px' },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                                <Button
                                    type="submit"
                                    sx={{
                                        background: 'linear-gradient(90deg, #47627B 0%, #4A87BE 100%)',
                                        color: '#fff',
                                        px: 4,
                                        py: 1,
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #4A87BE 0%, #47627B 100%)',
                                        }
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}
 
export default AddEmployeeExperience; 


StarRating.propTypes = {
    rating: PropTypes.number.isRequired,
    setRating: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    formik: PropTypes.object.isRequired,
};